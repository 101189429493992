import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

interface Props {
    label: string
    icon?: IconProp
    image?: string
    hoverImage?: string
    altImage?: string
    activeTab: boolean
    openSection: () => void
}

const TabButton: React.FC<Props> = (props) => {
    const [hoverFlag, setHoverFlag] = useState(false)
    return (
        <div
            aria-label={props.label}
            className={`c-tab-button ${props.activeTab ? 'c-active-tab' : ''}`}
            onClick={() => props.openSection()}
            onMouseOver={() => setHoverFlag(true)}
            onMouseOut={() => setHoverFlag(false)}
            onTouchStart={() => setHoverFlag(true)}
            onTouchEnd={() => setHoverFlag(false)}
        >
            <div className="c-tab-button__icon u-medium-text">
                <div className="icon">
                    {props.icon && <FontAwesomeIcon icon={props.icon} />}
                    {props.image && props.hoverImage && (
                        <img alt={props.altImage} src={props.activeTab || hoverFlag ? props.image : props.hoverImage} />
                    )}
                </div>
            </div>
            <p className="u-medium-text button-text">{props.label}</p>
            <div className="c-tab-button__arrow-icon">
                <FontAwesomeIcon icon={faChevronRight} />
            </div>
        </div>
    )
}

export default TabButton
