import axios from 'axios'

const CustomAxios = axios.create({
    baseURL: 'https://solutions.api-services.cloud/mailer/',
    headers: {
        'Content-Type': 'application/json'
    }
})

export const toCamelCase: any = (object: any) => {
    let transformedObject = object
    if (typeof object === 'object' && object !== null) {
        if (object instanceof Array) {
            transformedObject = object.map(toCamelCase)
        } else {
            transformedObject = {}
            Object.keys(object).map((key: any) => {
                if (object[key] !== undefined) {
                    const newKey = key.replace(/(_\w)|(-\w)/g, (k: string[]) => k[1].toUpperCase())
                    transformedObject[newKey] = toCamelCase(object[key])
                }
                return
            })
        }
    }
    return transformedObject
}

export const toSnackCase: any = (object: any) => {
    let transformedObject = object
    if (typeof object === 'object' && object !== null) {
        if (object instanceof Array) {
            transformedObject = object.map(toSnackCase)
        } else {
            transformedObject = {}
            Object.keys(object).map((key: any) => {
                if (object[key] !== undefined) {
                    const newKey = key
                        .replace(/\.?([A-Z]+)/g, (_: any, y: string) => {
                            return `_${y.toLowerCase()}`
                        })
                        .replace(/^_/, '')
                    transformedObject[newKey] = toSnackCase(object[key])
                }
                return
            })
        }
    }
    return transformedObject
}

CustomAxios.interceptors.response.use(
    (response) => {
        response.data = toCamelCase(response.data)
        return response
    },
    async (error) => {
        return Promise.reject(error)
    }
)

CustomAxios.interceptors.request.use(
    (config) => {
        config.data = toSnackCase(config.data)
        return config
    },
    async (error) => {
        return Promise.reject(error)
    }
)

export default CustomAxios
