import React from 'react'

interface Props {
    placeholder?: string
    onValueChange: (newString: string) => void
}

const TextInput: React.FC<Props> = (props) => {
    return (
        <input
            aria-label={props.placeholder}
            className="c-text-input"
            type="text"
            placeholder={props.placeholder}
            onChange={(e) => props.onValueChange(e.target.value)}
        />
    )
}

export default TextInput
