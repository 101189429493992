import { faGear, faMobileScreen, faWifi } from '@fortawesome/free-solid-svg-icons'
import React, { useMemo, useState } from 'react'
import TabButton from '../components/TabButton'
import {
    CONNECT,
    FIRST_IMAGE_TEXT,
    FOURTH_IMAGE_TEXT,
    MOBILE,
    PEPERONI_CONTENT,
    RETRO,
    SECOND_IMAGE_TEXT,
    THIRD_IMAGE_TEXT,
} from '../resources/string-constants'
import peperoniBlackAndWhite from '../resources/images/pep_black_white.png'
import amazonAlexa from '../resources/images/amazon-alexa.png'
import googleHome from '../resources/images/google-home.png'
import fabita from '../resources/images/fabita_logo.png'
import actek from '../resources/images/actek-logo.png'
import amazonEcho from '../resources/images/amazon-echo.png'
import peperoniWhite from '../resources/images/pep_white.png'
import peperoniBlack from '../resources/images/pep_black.png'
import TextInput from '../components/TextInput'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, A11y, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import CustomAxios from '../utility/customAxios'

const listElements = [
    {
        id: 0,
        label: 'Peperoni',
        image: peperoniWhite,
        hoverImage: peperoniBlack,
    },
    {
        id: 1,
        label: '22 Connect',
        icon: faWifi,
    },
    {
        id: 2,
        label: '22 Mobile',
        icon: faMobileScreen,
    },
    {
        id: 3,
        label: '22 Retro',
        icon: faGear,
    },
]

const carouselElements = [
    {
        id: 0,
        imageClass: 'first',
        title: 'Smart Kitchen',
        subtitle: FIRST_IMAGE_TEXT,
    },
    {
        id: 1,
        imageClass: 'second',
        title: 'Piani cottura Smart',
        subtitle: SECOND_IMAGE_TEXT,
    },
    {
        id: 2,
        imageClass: 'third',
        title: 'Termometri Smart',
        subtitle: THIRD_IMAGE_TEXT,
    },
    {
        id: 3,
        imageClass: 'fourth',
        title: 'Forni Smart',
        subtitle: FOURTH_IMAGE_TEXT,
    },
]

const HomePage: React.FC = () => {
    const [selectedSection, setSelectedSection] = useState(listElements[0].label)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [organization, setOrganization] = useState('')
    const [conditionsAccepted, setConditionsAccepted] = useState(false)

    const resetData = () => {
        setName('')
        setEmail('')
        setPhone('')
        setOrganization('')
    }

    const sendData = async () => {
        try {
            const mailData = {
                sender: 'default',
                to: ['aime.diatta@22hbg.it'],
                subject: 'Smart Kitchen - Nuovo contatto',
                body: '',
                html: `${name}<br>Azienda: ${organization}<br>Email: ${email}<br>Telefono: ${phone}`,
            }
            await CustomAxios.post('send_mail', mailData)
            resetData()
        } catch (error) {
            console.error(error)
        }
    }

    const requiredDataIsCorrect = useMemo(() => {
        return conditionsAccepted && name !== '' && email !== '' && phone !== ''
    }, [name, email, phone, conditionsAccepted])

    const getSectionContent = useMemo(() => {
        switch (selectedSection) {
            case 'Peperoni':
                return PEPERONI_CONTENT.split('.').map((paragraph: string) => paragraph && <p key={paragraph} className="u-medium-text paragraph-text">{paragraph}.</p>)
            case '22 Connect':
                return CONNECT.split('.').map((paragraph: string) => paragraph && <p key={paragraph} className="u-medium-text paragraph-text">{paragraph}.</p>)
            case '22 Mobile':
                return MOBILE.split('.').map((paragraph: string) => paragraph && <p key={paragraph} className="u-medium-text paragraph-text">{paragraph}.</p>)
            case '22 Retro':
                return RETRO.split('.').map((paragraph: string) => paragraph && <p key={paragraph} className="u-medium-text paragraph-text">{paragraph}.</p>)

            default:
                return <p>{PEPERONI_CONTENT}</p>
        }
    }, [selectedSection])

    return (
        <div>
            <Swiper
                modules={[Navigation, Pagination, A11y, Autoplay]}
                slidesPerView={1}
                navigation
                autoplay
                pagination={{ clickable: true }}
            >
                {carouselElements.map((element) => (
                    <SwiperSlide key={element.id}>
                        <div className="o-carousel-element">
                            <div className={`o-carousel-image ${element.imageClass}-carousel-image`} />
                            <div className="o-dark-cover" />
                            <div
                                className={`o-carousel-text-wrapper ${
                                    element.id % 2 === 0
                                        ? 'o-carousel-text-wrapper-left'
                                        : 'o-carousel-text-wrapper-right'
                                }`}
                            >
                                <p className="u-slide-title">{element.title}</p>
                                <p className="u-small-text o-slide-subtitle">{element.subtitle}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="interactive-section">
                <p className="u-large-text o-section-title">La piattaforma modulare per la cucina domotica</p>
                <div className="interactive-section__content">
                    <div className="tab-triggers-list">
                        {listElements.map((listElement) => (
                            <TabButton
                                key={listElement.id}
                                openSection={() => setSelectedSection(listElement.label)}
                                label={listElement.label}
                                icon={listElement.icon}
                                image={listElement.image}
                                altImage={listElement.label}
                                hoverImage={listElement.hoverImage}
                                activeTab={listElement.label === selectedSection}
                            />
                        ))}
                    </div>
                    <div className="section-info-container">
                        <h2 className="u-giant-text">{selectedSection}</h2>
                        {getSectionContent}
                    </div>
                </div>
            </div>
            <div className="special-container ingredient-section">
                <div className="o-dark-cover" />
                <div className="special-container__content">
                    <div className="special-container__content__element">
                        <div className="o-pep-logo-container">
                            <img alt="Peperoni" src={peperoniBlackAndWhite} />
                        </div>
                    </div>
                    <div className="special-container__content__element">
                        <p className="u-large-text u-white-text">L&apos;ingrediente segreto che fa la differenza</p>
                        <p className="u-medium-text u-white-text">
                            Grazie alla sua innovazione tecnologica, Peperoni ti aiuta a gestire e governare
                            l&apos;interazione tra l&apos;app e i dispositivi.
                        </p>
                    </div>
                </div>
            </div>
            <div className="special-container testimony-section">
                <div className="special-container__content">
                    <p className="u-large-text o-section-title u-black-text">
                        Alcuni partner che adottano le nostre soluzioni
                    </p>
                    <div className="o-logo-list">
                        <div className="o-logo-list__element">
                            <img alt="ACTEK" src={actek} />
                        </div>
                        <div className="o-logo-list__element">
                            <img alt="Fabita" src={fabita} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="special-container smartspeaker-container">
                <div className="o-dark-cover" />
                <div className="special-container__content">
                    <p className="u-large-text o-section-title u-white-text">
                        Ci integriamo anche con i tuoi smart speaker
                    </p>
                    <div className="o-logo-list">
                        <div className="o-logo-list__element">
                            <img alt="Alexa" src={amazonAlexa} />
                        </div>
                        <div className="o-logo-list__element">
                            <img alt="Amazon Echo" src={amazonEcho} />
                        </div>
                        <div className="o-logo-list__element">
                            <img alt="Google Home" src={googleHome} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="interactive-section">
                <p className="u-large-text u-bold-text o-section-title">
                    Hai bisogno di una consulenza per il tuo progetto di cucina domotica? Non esitare a contattarci.
                </p>
                <div className="o-input-container">
                    <div className="o-input-grid">
                        <TextInput placeholder="Nome e cognome" onValueChange={(newValue) => setName(newValue)} />
                        <TextInput placeholder="Email" onValueChange={(newValue) => setEmail(newValue)} />
                        <TextInput placeholder="Telefono" onValueChange={(newValue) => setPhone(newValue)} />
                        <TextInput placeholder="Azienda" onValueChange={(newValue) => setOrganization(newValue)} />
                    </div>
                    <div className="o-checkbox-container">
                        <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value={conditionsAccepted ? 1 : 0}
                            onChange={() => setConditionsAccepted(!conditionsAccepted)}
                        />
                        <label htmlFor="vehicle1">
                            Presa visione dell&apos;
                            <a href="https://22hbg.com/privacy-policy/" rel="noreferrer" target="_blank">
                                Informativa privacy
                            </a>
                            .
                        </label>
                    </div>
                    <button
                        className="o-send-button"
                        onClick={async () => await sendData()}
                        disabled={!requiredDataIsCorrect}
                    >
                        Invia
                    </button>
                </div>
            </div>
        </div>
    )
}

export default HomePage
