export const PEPERONI_CONTENT =
    'L’area dedicata alla gestione dei contenuti dedicati alla tua APP e aiutare gli utilizzatori ad eseguire ricette passo passo per trasformarli in Chef! Un unica area per gestire ricette in più lingue, definire dove renderle visibili in maniera sicura ottimizzando i costi di gestione. Le API condivise permettono un aggiornamento in tempo reale dei contenuti permettendo all’ufficio marketing di definire una strategia di comunicazione dinamica e efficace. La piattaforma è totalmente integrabile con sistemi terzi permettendo a richiesta di implementare anche meccanismi di profilazione utenti.'
export const CONNECT =
    'Il modulo di 22HBG in grado di integrare gli SDK dei maggiori produttori di appliances in un unica soluzione. L’architettura è basata su librerie aperte personalizzabili e integrabili. In base alle esigenze del produttore, si possono fare implementazioni di estensioni dedicate.Il set base permette l’interazione con soluzioni basate su moduli induzione EGO piuttosto che sonde di temperature wireless. Altre modalità di interazione con protocolli diversi sono disponibili.'
export const MOBILE =
    'Il cuore del sistema è l’applicazione per smartphone, che collega le parti fisiche per monitorarle, gestirle anche in retroazione e offre la fruizione di contenuti a tema cucina. Con l’applicazione, si sfruttano le potenzialità degli strumenti di cottura tramite la loro integrazione con un ambiente digitale e contenuti dedicati, la selezione dei device giusti, e sistemi di intelligenza artificiale come Amazon Alexa.'
export const RETRO =
    'Grazie al modulo che gestisce la retroazione con i termometri connessi, l’applicazione monitora temperatura e cottura dando l’alert quando il grado di cottura richiesto e stato raggiunto. Questo permette di regolare la temperatura in base alla ricetta in esecuzione.'

export const FIRST_IMAGE_TEXT =
    'La soluzione modulare giusta per il tuo progetto di cucina domotica. Un sistema totalmente integrabile che migliora l’interazione con gli elettrodomestici in modo da far vivere ai tuoi clienti un esperienza semplice, efficace e sicura in cucina.'
export const SECOND_IMAGE_TEXT =
    'Una soluzione integrata, scalabile su mercati diversi per lingua, ricette, brand aziendali diversi con una cabina di regia unica.'
export const THIRD_IMAGE_TEXT =
    'Un ecosistema della cucina domotica basato su protocolli di comunicazione Bluetooth e Wi-FI per estendere e migliorare l’esperienza utente.'
export const FOURTH_IMAGE_TEXT =
    'Forni a gas o Forni elettrici multifunzione, il sistema gestisce l’interazione in maniera trasparente per avere insieme il meglio di entrambi le tecnologie.'
